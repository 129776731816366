import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import variables from "../constants/variables"

const Footer = ({ path }) => {
  return (
    <StyledFooter className="py-lg-4">
      <div className="flex-container d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center mx-5">
        <div className="py-3">
          <a
            href="https://www.heftigewebseiten.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            © Heftige Webseiten {new Date().getFullYear()}
          </a>
        </div>
        <div className="mb-4 my-sm-auto">
          {path !== "/" && (
            <AniLink cover to="/" bg={variables.COLORS.primaryColor}>
              {" "}
              Home{" "}
            </AniLink>
          )}
          {path !== "/impressum/" && (
            <AniLink cover to="/impressum/" bg={variables.COLORS.primaryColor}>
              {" "}
              Impressum{" "}
            </AniLink>
          )}
          {path !== "/privacy/" && (
            <AniLink cover to="/privacy/" bg={variables.COLORS.primaryColor}>
              {" "}
              Datenschutz{" "}
            </AniLink>
          )}
        </div>
        <div>
          <AniLink cover to="/#" bg={variables.COLORS.primaryColor}>
            Office EBE
          </AniLink>
        </div>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  color: white;
  background: ${variables.COLORS.primaryColor};
  padding-bottom: 100px;
  .footer-container {
    max-width: 1140px;

    .flex-container {
      min-height: 78px;
    }
  }
  a {
    color: ${variables.COLORS.mainWhite};
    text-decoration: none;
    margin-right: 10px;
  }
`

export default Footer
