export default [
  {
    path: "/#",
    text: "Home",
    hash: "/#top",
  },
  {
    path: "/#performances",
    text: "Leistungen",
    hash: "/#performances",
  },
  {
    path: "/#contact",
    text: "Kontakt",
    hash: "/#contact",
  },
]
